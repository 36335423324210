import React from "react";
import Layout from "../../components/Layout";

// eslint-disable-next-line
export default () => (
  <Layout>
    <div>
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url('/img/becs-hill-climb-2022.jpeg')`,
        }}
      >
        <h1
          className="has-text-weight-bold is-size-1"
          style={{
            boxShadow: "0.5rem 0 0 #E3B74F, -0.5rem 0 0 #E3B74F",
            backgroundColor: "#E3B74F",
            color: "white",
            padding: "1rem",
          }}
        >
          Thank you!
        </h1>
      </div>
      <section className="section">
        <div className="container">
          <div className="content">
            <p>
              Wow! You are so retro sending me an email via a webform. Thanks!
              I'll be in touch soon.
            </p>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);
